import ACTIONS from './index'
import axios from 'axios'

const proxy = ''

export const dispatchLogin = () =>{
    return {
        type: ACTIONS.LOGIN
    }
}

export const fetchUser = async (token) =>{
    const res = await axios.get(`${proxy}/user/info`,{
        headers: {Authorization: token}
    })
    return res
}

export const dispatchGetUser = (res) =>{
    return {
        type: ACTIONS.GET_USER,
        payload: {
            user: res.data,
            isSuperAdmin: res.data.role === 0 ? true : false
        }
    }
}