import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {dispatchLogin, fetchUser, dispatchGetUser} from '../../../../../redux/actions/authAction'
import axios from 'axios'
import { errMsg, successMsg, showMsgInSeconds} from '../../../utils/msg/Msg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fx from '../../../utils/fx'

import Profile from '../Profile'

import './daily.scss'
import Loader from '../../../utils/loader/Loader'

const proxy = ''

const msgDuration = 3

const initMsg = {err: '', success: ''}

export default function Daily() {
    const dispatch = useDispatch()
    const token = useSelector(state => state.token)
    const [games, setGames] = useState([])
    const [words, setWords] = useState(Array.from(Array(31).keys()).map(el => ""))
    const [lastUpdate, setLastUpdate] = useState(null)
    const [serverDate, setServerDate] = useState(null)
    
    // Date
    const today = new Date()
    const [calendar, setCalendar] = useState({year: today.getFullYear(), month: today.getMonth(), date: today.getDate()})

    // Msg
    const [msg, setMsg] = useState({games: initMsg, game: initMsg, modal: initMsg})

    // Loading
    const [loadingPage, setLoadingPage] = useState(true)
    const [loadingGames, setLoadingGames] = useState(true)
    const [loadingWords, setLoadingWords] = useState(true)

    //CB
    const [cbUser, setCbUser] = useState(false)
    const [cbGames, setCbGames] = useState(false)
    const [cbWords, setCbWords] = useState(false)
    const [elementID, setElementID] = useState(null)

    let element = null
    if(elementID) element = games?.find(el => el._id === elementID)

    useEffect(() => {
        if(token){
            const getUser = () => {
                dispatch(dispatchLogin())
                return fetchUser(token).then(res => {
                    dispatch(dispatchGetUser(res))
                })
            }
            getUser()
        }
    }, [token, dispatch, cbUser])

    useEffect(() => {
        const getGames = async() => {
            try {
                const res = await axios.get(`${proxy}/data/games/get/all`, {
                    headers: { Authorization: token }
                })
                setGames(res.data.games)
                setLoadingPage(false)
            } catch (err) {
                err.response.data.msg &&
                showMsgInSeconds(setMsg, msg, 'games', {err: err.response.data.msg, success: ''}, msgDuration)
                setLoadingPage(false)
            }
        }
        if(token) getGames()
    }, [token, cbGames])

    useEffect(() => {
        setLoadingWords(true)
        setWords(Array.from(Array(31).keys()).map(el => ""))
        const getWords = async() => {
            try {
                const res = await axios.get(`${proxy}/data/games/month-words/by-query?gameID=${elementID}&month=${calendar.month + 1}&year=${calendar.year}`, {
                    headers: { Authorization: token }
                })
                setWords(res.data.words)
                setServerDate(res.data.today)
                setLastUpdate(res.data.lastUpdate)
                setLoadingWords(false)
            } catch (err) {
                err.response.data.msg &&
                alert(err.response.data.msg)
                setLoadingWords(false)
            }
        }
        if(token && elementID) getWords()
    }, [token, calendar.month, calendar.year, cbWords, elementID])

    const selectGame = e => {
        e.preventDefault()
        const {id} = e.target
        if(id === elementID) return setElementID('')
        setElementID(id)
        setCbGames(!cbGames)
        setCbWords(!cbWords)
    }

    const monthArray = (m, y) => {
        const lengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        const length = m === 1 ? y % 4 === 0 ? lengths[m] + 1 : lengths[m] : lengths[m]
        return Array.from(Array(length).keys())
    }

    const changeMonth = (option) => {
        if(option === '+') {
            if(calendar.month === 11) return setCalendar({...calendar, month: 0, year: calendar.year + 1})
            return setCalendar({...calendar, month: calendar.month + 1})
        } else {
            if(calendar.month === 0) return setCalendar({...calendar, month: 11, year: calendar.year - 1})
            return setCalendar({...calendar, month: calendar.month - 1})
        }
    }

    const updateAllUpcomingWords = async() => {
        const confirmContinue = window.confirm(`Do you want to update all upcoming words?`)
        if(!confirmContinue) return
        try {
            const res = await axios.put(`${proxy}/data/games/update-all-words`, {gameID: elementID}, {
                headers: { Authorization: token }
            })
            setLoadingWords(false)
            setCbWords(!cbWords)
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
        }
    }
    
    return (
        <Profile>
            {loadingPage ? <div style={{fontSize: '100px'}}><Loader/></div>
            :
            <div id='daily'  className='auth-wrap'>
                <section>
                    <div className='title-container'>
                        <div className='flexrow align-ce'>
                            <p className='title'>Choose a game</p>        
                            <div className="msg">
                                {msg.games.err && errMsg(msg.games.err)}
                                {msg.games.success && successMsg(msg.games.success)}
                            </div>
                        </div>
                    </div>
                    <div className='cards-container flexrow'>
                        {games?.map(game => 
                            <div key={game._id} id={game._id} className={game._id === elementID ? 'selected-card card' : 'card'} onClick={selectGame}>{game.name.toUpperCase()}</div>
                        )}
                    </div>
                </section>

                {elementID &&
                <section id='element' className='element-page'>
                    <div className='selection'>
                        <div className='month-header'>
                            <div className='month-selection'>
                                <span onClick={()=>changeMonth('+')}>+</span>
                                <span className='current' onClick={()=>setCalendar({...calendar, month: new Date().getMonth()})}>&#9632;</span>
                                <span onClick={()=>changeMonth('-')}>&#8722;</span>
                            </div>
                            <p className='month'>{new Date(calendar.year, calendar.month).toLocaleString('en-US',{month: 'long'})}</p>
                        </div>
                        <div className='year-header'>
                            <p className='year'>{calendar.year}</p>
                            <div className='year-selection'>
                                <span onClick={()=>setCalendar({...calendar, year: calendar.year + 1})}>+</span>
                                <span className='current' onClick={()=>setCalendar({...calendar, year: new Date().getFullYear()})}>&#9632;</span>
                                <span onClick={()=>setCalendar({...calendar, year: calendar.year - 1})}>&#8722;</span>
                            </div>
                        </div>
                    </div>
                    {loadingWords ? <div style={{fontSize: '100px'}} className='month-loader'><Loader/></div>:
                    <div className='loaded-container'>
                        <div className='update-buttons'>
                            <button onClick={updateAllUpcomingWords}>Update All Upcoming</button>
                        </div>
                        <div className='month-display'>
                            <div className='month-body'>
                            {monthArray(calendar.month, calendar.year).map(day => {
                                return <DayCard key={day} day={day + 1} />
                            })}
                            </div>
                        </div>
                    </div>}
                </section>
                }
            </div>
            }
        </Profile>
    )
    function DayCard ({day}) {
        const isLastUpdateDate = String(day) === String(lastUpdate?.split('/')[1]) && String(calendar.month + 1) === String(lastUpdate?.split('/')[0]) && String(calendar.year) === String(lastUpdate?.split('/')[2])
        const isServerDate = String(day) === String(serverDate?.split('/')[1]) && String(calendar.month + 1) === String(serverDate?.split('/')[0]) && String(calendar.year) === String(serverDate?.split('/')[2])
        const isToday = calendar.date === day && calendar.month === new Date().getMonth() && calendar.year === new Date().getFullYear()
        return (
            <div className='day-card'>
                <div className='day-top'>
                    <span className='day-weekday'>{new Date(calendar.year, calendar.month, day).toLocaleString('en-US',{weekday: 'long'}).slice(0,1)}</span>
                    <span
                        title={isLastUpdateDate ? 'Last Creation of Daily Words' : isServerDate ? 'Server\'s Date' : isToday ? 'Today' : ''} 
                        className={isLastUpdateDate ? 'day-number-lastUpdate circle' : isServerDate ? 'day-number-server circle' : isToday ? 'day-number-today circle' : 'day-number'}>{day}
                    </span>
                </div>
                <div className='day-word'>{words?.filter(el => new Date(el.launchDate).getDate() === day)[0]?.word}</div>
            </div>
        )
    }
}
