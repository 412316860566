import React, {useEffect} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {dispatchLogin, fetchUser, dispatchGetUser} from './redux/actions/authAction'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'

import Body from './components/body/Body';

import './App.css';

import { fab } from '@fortawesome/free-brands-svg-icons'
import { faRotate, faExclamationCircle, faEllipsis , faCashRegister, faCloudArrowUp, faCalendarDays, faChartSimple, faQuestion, faGear, faArrowTurnDown, faCircleXmark, faInfo, faShareNodes, faVolumeHigh, faVolumeXmark, faCirclePlus, faArrowUpFromBracket, faPenToSquare, faTrash, faXmark, faLock} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faRotate, faExclamationCircle, faEllipsis, faCashRegister, faCloudArrowUp, faCalendarDays, faChartSimple, faQuestion, faGear, faArrowTurnDown, faCircleXmark, faInfo, faShareNodes, faVolumeHigh, faVolumeXmark, faCirclePlus, faArrowUpFromBracket, faPenToSquare, faTrash, faXmark, faLock)

const proxy = ''

function App() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.token)
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    const firstLogin = localStorage.getItem('firstlogin')
    if(firstLogin){
      const getToken = async () => {
        const res = await axios.post(`${proxy}/user/refresh_token`, null)
        dispatch({type: 'GET_TOKEN', payload: res.data.accessToken})
      }
      getToken()
    }
  }, [auth.isLogged, dispatch])

  useEffect(() => {
    if(token){
      const getUser = () => {
        dispatch(dispatchLogin())

        return fetchUser(token).then(res => {
          dispatch(dispatchGetUser(res))
        })
      }
      getUser()
    }
  }, [token, dispatch])

  return (
    <Router>
      <div className="App">
        <Body />
      </div>
    </Router>
  );
}

export default App;
