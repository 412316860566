import React, { useState, useEffect } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {dispatchLogin, fetchUser, dispatchGetUser} from '../../../src/redux/actions/authAction'
import {Routes, Route} from 'react-router-dom'
import './body.scss'

import Login from './auth/login/Login'
import Register from './auth/register/Register'
import Activation from './auth/activation/Activation'
import Forgot from './auth/forgot/Forgot'
import ResetPassword from './auth/resetPassword/ResetPassword'
import General from './auth/profile/general/General'
import GamePage from './non-auth/gamePage/GamePage'

import Games from './auth/profile/games/Games'
import Daily from './auth/profile/daily/Daily'
import Stats from './auth/profile/stats/Stats'
import Users from './auth/profile/users/Users'
import Banners from './auth/profile/banners/Banners'

export default function Body() {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const token = useSelector(state => state.token)
    const {isLogged} = auth
    const [subdomain, setSubdomain] = useState('')

    useEffect(() => {
        if(token){
            const getUser = () => {
                dispatch(dispatchLogin())
                return fetchUser(token).then(res => {
                    dispatch(dispatchGetUser(res))
                })
            }
            getUser()
        }
    }, [token, dispatch])

    useEffect(() => {
        const host = window.location.host;
        // console.log(host)
        const arr = host.split('.')
        // console.log(arr[0])
        if (arr.length === 3) {
            setSubdomain(arr[0])
        }
    }, [])

    return (
        <Routes>
            <Route exact path="/" element={<GamePage />} />
            <Route exact path="/motuz-admin-login" element={isLogged ? <General /> : <Login />}/>
            <Route exact path="/register" element={<Register />}/>
            <Route exact path="/activation/:token" element={<Activation />}/>

            <Route exact path="/forgot" element={<Forgot/>}/>
            <Route exact path="/reset-pass/:token" element={<ResetPassword />}/>

            <Route exact path="/profile/general" element={isLogged ? <General /> : <Login />} />
            <Route exact path="/profile/games" element={isLogged ? <Games /> : <Login />} />
            <Route exact path="/profile/banners" element={isLogged ? <Banners /> : <Login />} />
            <Route exact path="/profile/daily-words" element={isLogged ? <Daily /> : <Login />} />
            <Route exact path="/profile/stats" element={isLogged ? <Stats /> : <Login />} />
            <Route exact path="/profile/users" element={isLogged ? <Users /> : <Login />} />
        </Routes>
    )
}
