import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {dispatchLogin, fetchUser, dispatchGetUser} from '../../../../../redux/actions/authAction'
import axios from 'axios'
import { errMsg, successMsg, showMsgInSeconds} from '../../../utils/msg/Msg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fx from '../../../utils/fx'

import Profile from '../Profile'

import './banners.scss'
import Loader from '../../../utils/loader/Loader'
import Modal from '../../../utils/modal/Modal'

const proxy = ''

const msgDuration = 3

const initMsg = {err: '', success: ''}

const initData = {
    banner: { name: '', message: '', url: ''}
}

export default function Banners() {
    const dispatch = useDispatch()
    const token = useSelector(state => state.token)
    const [data, setData] = useState(initData)
    const [banners, setBanners] = useState(null)

    // Msg
    const [msg, setMsg] = useState({banners: initMsg, game: initMsg, modal: initMsg})

    // Loading
    const [loadingPage, setLoadingPage] = useState(true)

    //CB
    const [cbUser, setCbUser] = useState(false)
    const [cbGames, setCbGames] = useState(false)
    const [cbBanners, setCbBanners] = useState(false)

    // Modal
    const [modalOpened, setModalOpened] = useState(false)

    useEffect(() => {
        if(token){
            const getUser = () => {
                dispatch(dispatchLogin())
                return fetchUser(token).then(res => {
                    dispatch(dispatchGetUser(res))
                })
            }
            getUser()
        }
    }, [token, dispatch, cbUser])

    useEffect(() => {
        setLoadingPage(true)
        setBanners([])
        const getBanners = async() => {
            try {
                const res = await axios.get(`${proxy}/data/banners/get/all`, {
                    headers: { Authorization: token }
                })
                setBanners(res.data.banners)
                setLoadingPage(false)
            } catch (err) {
                err.response.data.msg &&
                setLoadingPage(false)
            }
        }
        if(token) getBanners()
    }, [token, cbBanners])

    const closeModal = (set) => {
        set(false)
    }
    
    const handleChangeData = e => {
        const {id, value, checked, files} = e.target
        const file = files ? files[0] : null
        const [input, type, prop] = id.split(' ')

        let existingFiles = []
        if(input === "file"){
            if(!file) {
                return showMsgInSeconds(setMsg, msg, 'modal', {err: "No image file selected", success: ''}, msgDuration)
            }
            if(data.report.images.filter(el => el.name === file.name).length > 0)
                return showMsgInSeconds(setMsg, msg, 'modal', {err: "Coincidencia de nombre de archivo", success:''}, msgDuration)
            if(file.type !== 'image/jpeg' && file.type !== 'image/png')
                return showMsgInSeconds(setMsg, msg, 'modal', {err: "Incorrect format", success:''}, msgDuration)
            if(file.size > 1024 * 1024)
                return showMsgInSeconds(setMsg, msg, 'modal', {err: "File is too large", success: ''}, msgDuration)

            existingFiles = data.images
        }
        
        const fvalue = input === "checkbox" ? checked : input === "file" ? [...existingFiles, file] : value
        setData({...data, [type]: {...data[type], [prop]: fvalue}})
    }

    const clearInputs = e => {
        e.preventDefault()
        setData({...data})
        document.getElementsByName('canReset').forEach(el => {
            const inputType = el.id.split(' ')[0]
            el.value = inputType === 'color' ? '#000000' : ''
        })
    }

    const addNewBanner = async() => {
        const {name, url, message} = data.banner
        try {
            const res = await axios.post(`${proxy}/data/banners/add/one`, {name, url, message},{
                headers: { Authorization: token }
            })
            setData(initData)
            setCbBanners(!cbBanners)
            closeModal(setModalOpened)
        } catch (err) {
            err.response.data.msg &&
            showMsgInSeconds(setMsg, msg, 'modal', {err: err.response.data.msg, success: ''}, msgDuration)
        
        }
    }

    const handleUpdateField = async(e, element) => {
        e.preventDefault()
        const {id, value} = e.target
        const [input, prop] = id.split(' ')
        if(value === element[prop]) return
        if(!value) return document.getElementById(id).value = element[prop]
        const confirmContinue = window.confirm(`Update field with '${value}' ?`)
        if(!confirmContinue) return document.getElementById(id).value = element[prop]
        
        const loaderID = input === 'radio' ? 'loader-'+String(e.target.parentElement.parentElement.parentElement.id.split('-')[2]) : 'loader-'+String(e.target.parentElement.id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'

        try {
            const res = await axios.put(`${proxy}/data/banners/update/by-id/${element._id}`, {prop, value},{
                headers: { Authorization: token }
            })
            setCbBanners(!cbBanners)
            document.getElementById(loaderID).style.display = 'none'
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const handleUpdateImage = async(e, element) => {
        //e.preventDefault()
        const file = e.target.files[0]

        if(!file) return alert("No file selected")
        
        if(file.type !== 'image/jpeg' && file.type !== 'image/png')
            return alert("Incorrect format")

        if(file.size > 1024 * 1024)
            return alert("File is too large")

        const loaderID = 'loader-'+String(e.target.parentElement.id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'
        try {
            let formData = new FormData()
            formData.append('file', file)
            formData.append('prop', e.target.id.split('-')[1])
            formData.append('folder', 'banners')
            const res = await axios.post(`${proxy}/file/upload_image/by-id/${element._id}`, formData, {
                headers: {
                    Authorization: token,
                    'content-type': 'multipart/form-data'
                }
            })
            setCbBanners(!cbBanners)
            document.getElementById(loaderID).style.display = 'none'
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const handleUpdateActive = async(e, element) => {
        const {value} = e.target
        if(value && (!element.message && !element.image)) {
            e.target.value = element?.isActive
            return alert('An active banner game must have a message or image')
        }
        const loaderID = 'loader-'+String(e.target.parentElement.parentElement.parentElement.id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'

        try {
            const res = await axios.put(`${proxy}/data/banners/activate/by-id/${element._id}`, {prop: 'isActive', value},{
                headers: { Authorization: token }
            })
            document.getElementById(loaderID).style.display = 'none'
            setCbBanners(!cbBanners)
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const handleDeleteImage = async(e, element) => {
        e.preventDefault()
        const [action, prop] = e.target.id.split(' ')
        const confirmContinue = window.confirm(`Remove this element?`)
        if(!confirmContinue) return
        
        const loaderID = 'loader-'+String(e.target.parentElement.children[2].id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'
        try {
            const res = await axios.delete(`${proxy}/data/banners/delete-image/by-id/${element._id}?prop=${prop}&folder=banners`,{
                headers: { Authorization: token }
            })
            document.getElementById(loaderID).style.display = 'none'
            setCbBanners(!cbBanners)
        } catch (err) {
            err.response?.data?.msg && alert(err.response?.data?.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const removeElement = async(e, element) => {
        e.preventDefault()
        const confirmContinue = window.confirm(`Delete banner ${element?.name.toUpperCase()} ?`)
        if(!confirmContinue) return
        setLoadingPage(true)
        try {
            const res = await axios.delete(`${proxy}/data/banners/delete/by-id/${element._id}`,{
                headers: { Authorization: token }
            })
            setCbBanners(!cbBanners)
            setLoadingPage(false)
        } catch (err) {
            err.response.data.msg && alert(err.response.data.msg)
            setLoadingPage(false)
        }
    }

    return (
        <Profile>
            <Modal open={modalOpened} close={()=> closeModal(setModalOpened)}>
                <div id="modal-create"className="modal-container">
                    <p className="modal-title">Create a New Banner</p>
                    <section className='flexcol'>
                        <div className='section-header'>
                            <p className='modal-section-title'>Banner Info</p>
                            <p className='modal-section-subtitle'>Complete the following information for the new banner</p>
                        </div>
                        <div className="input-wrap flexrow align-ce">
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="text" placeholder="Banner name" defaultValue={data.banner.name} name='canReset' id="text banner name" onChange={handleChangeData}/>
                            </div>
                        </div>
                        <div className="input-wrap flexrow align-ce">
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="text" placeholder="URL" defaultValue={data.banner.url} name='canReset' id="text banner url" onChange={handleChangeData}/>
                            </div>
                        </div>
                        <div className="input-wrap flexrow align-ce">
                            <i className="fas fa-caret-right"> </i>
                            <div className='input-wrap-input-element'>
                                <input className='input-element' type="text" placeholder="Message" defaultValue={data.banner.message} name='canReset' id="text banner message" onChange={handleChangeData}/>
                            </div>
                        </div>
                    </section>
                    <div className='button-container'>   
                        <button onClick={addNewBanner}>Create Banner</button>
                        <button onClick={clearInputs}>Reset fields</button>  
                    </div>
                    <div className="modal-msg">
                        {msg.modal.err && errMsg(msg.modal.err)}
                        {msg.modal.success && successMsg(msg.modal.success)}
                    </div>
                </div>
            </Modal>
            {loadingPage ? <div style={{fontSize: '100px'}}><Loader/></div>
            :
            <div id='banners'  className='auth-wrap'>
                <section>
                    <div className='title-container'>
                        <div className='flexrow align-ce'><p className='title'>Banners</p><button className='add-game-button' onClick={()=>setModalOpened(true)}><FontAwesomeIcon className='clickable' icon="fa-solid fa-circle-plus"/> Add New</button></div>
                        <div className="msg">
                            {msg.banners.err && errMsg(msg.banners.err)}
                            {msg.banners.success && successMsg(msg.banners.success)}
                        </div>
                    </div>
                    <div className='cards-container'>
                        {banners?.map(banner => 
                            <BannerInfoCard key={banner._id} data={banner} />
                        )}
                        {banners?.length === 0 && <div style={{width: '100%'}}>No data found</div>}
                    </div>
                </section>
            </div>
            }
        </Profile>
    )
    function BannerInfoCard ({data}) {
        return (
            <div className='banner-info-card' id={`banner-${data._id}`}>
                <div className='subsection-content margin-h-auto'>
                    <div className='content-card'>
                        <p className='content-card-label'>Name</p>
                        <div id='with-loader-00' className='update-field-container'>
                            <input className='update-field' id='input name' defaultValue={data?.name} onBlur={(e)=>handleUpdateField(e, data)}/>
                            <div id='loader-00' className='loader-container'><Loader /></div>
                        </div>
                    </div>
                    <div className='content-card'>
                        <p className='content-card-label'>URL</p>
                        <div id='with-loader-01' className='update-field-container'>
                            <input className='update-field' id='input url' defaultValue={data?.url}  style={{textTransform: 'lowercase'}} onBlur={(e)=>handleUpdateField(e, data)}/>
                            <div id='loader-01' className='loader-container'><Loader /></div></div>
                    </div>
                    <div className='content-card'>
                        <p className='content-card-label'>Message</p>
                        <div id='with-loader-02' className='update-field-container'>
                            <input className='update-field' id='input message' defaultValue={data?.message} onBlur={(e)=>handleUpdateField(e, data)}/>
                            <div id='loader-02' className='loader-container'><Loader /></div>
                        </div>
                    </div>
                    <div className='content-card'>
                        <p className='content-card-label'>Image</p>
                        {data?.image && <p className='remove' id='delete image' onClick={(e)=>handleDeleteImage(e, data)}>REMOVE</p>}
                        <div id='with-loader-03' className='update-field-container'>
                            <input type='file' className='hidden-file-input' id='file-image' onChange={(e)=>handleUpdateImage(e, data)}/>
                            {data?.image ?
                            <img className='update-field image-field' src={data?.image ?? ''} alt='banner img update field' onClick={()=>document.getElementById('file-image').click()}/>
                            :
                            <p className='update-field image-field' onClick={()=>document.getElementById('file-image').click()}>&#9656; Select new image</p>}
                            <div id='loader-03' className='loader-container'><Loader /></div>
                        </div>
                    </div>
                    <div className='content-card'>
                        <p className='content-card-label'>Active</p>
                        <div id='with-loader-04' className='update-field-container'>
                            <div className='radio-field'>
                                <div className='radio-label-input'>
                                    <label htmlFor={`active-${data._id}-true`}>Yes</label>
                                    <input type='radio' id={`active-${data._id}-true`} name={`active-${data._id}-true`} value={true} checked={data?.isActive ? true : false} onChange={(e)=>handleUpdateActive(e, data)}/>
                                </div>
                                <div className='radio-label-input'>
                                    <label htmlFor={`active-${data._id}-false`}>No</label>
                                    <input type='radio' id={`active-${data._id}-false`} name={`active-${data._id}-false`} value={false} checked={data?.isActive ? false : true} onChange={(e)=>handleUpdateActive(e, data)}/>
                                </div>
                            </div>
                            <div id='loader-04' className='loader-container'><Loader /></div>
                        </div>
                    </div>
                    <div className='button-container'>
                        <button className='delete-button' onClick={(e)=>removeElement(e, data)}>Erase Game</button>
                    </div>
                </div>
            </div>
        )
    }
}
