import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import { errMsg, successMsg } from '../../utils/msg/Msg'
import {isEmail} from '../../utils/validation'
import './forgot.scss'

const proxy = ''

export default function Forgot() {
    const [email, setEmail] = useState('')

    const [msg, setMsg] = useState({err:'', success:''})
    const {err, success} = msg

    const handleChange = (e) => {
        const email = e.target.value
        setEmail(email)
        setMsg({err:'', success:''})
    }

    const forgotPassword = async() => {
        if(!isEmail(email)) return setMsg({err: "Invalid email", success: ''})

        try {
            const res = await axios.post(`${proxy}/user/forgot`, {email})
            return setMsg({err: '', success: res.data.msg})
        } catch (err) {
            err.response.data.msg && setMsg({err: err.response.data.msg, success: ''})
        }
    }

    return (
        <div id="forgot" className='pre-auth-wrap'>
            <header>
                <div className='flexcol'>
                    <p className="title">Did you forget your password?</p>
                    <p className="subtitle">Type in your registered email</p>
                </div>
            </header>
            <div className="pre-auth-body">
                <div className="msg full-width">
                    {err && errMsg(err)}
                    {success && successMsg(success)}
                </div>
                <div className="input-wrap">
                    <label htmlFor="email">We'll send an reset password link to this email address</label>
                    <input type="email" name="email" id="email" value={email} onChange={handleChange} />
                </div>

                <button onClick={forgotPassword}>Send reset link to my email</button>

                <Link to="/motuz-admin-login">Go to Login page</Link>
            </div>
        </div>
    )
}
