import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {dispatchLogin, fetchUser, dispatchGetUser} from '../../../../../redux/actions/authAction'
import axios from 'axios'
import { errMsg, successMsg, showMsgInSeconds} from '../../../utils/msg/Msg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from '../../../utils/loader/Loader'
import Profile from '../Profile'
import { role } from '../../../utils/validation'

import './users.scss'

const proxy = ''
const defaultAvatar = "https://res.cloudinary.com/dq8tc58bo/image/upload/v1658208835/default%20avatar/default-avatar_xtoims.png"

const msgDuration = 3

const initMsg = {err: '', success: ''}

export default function Users() {
    const dispatch = useDispatch()
    const token = useSelector(state => state.token)
    const [users, setUsers] = useState([])

    const [elementID, setElementID] = useState(null)
    const [element, setElement] = useState(null)
    
    // Date
    const today = new Date()

    // Msg
    const [msg, setMsg] = useState({users: initMsg})

    // Loading
    const [loadingPage, setLoadingPage] = useState(true)
    const [loadingUsers, setLoadingUsers] = useState(true)
    const [loadingUser, setLoadingUser] = useState(true)

    //CB
    const [cbUser, setCbUser] = useState(false)
    const [cbUsers, setCbUsers] = useState(false)


    useEffect(() => {
        if(token){
            const getUser = async() => {
                dispatch(dispatchLogin())
                return fetchUser(token).then(res => {
                    dispatch(dispatchGetUser(res))
                })
            }
            getUser()
        }
    }, [token, dispatch, cbUser])

    useEffect(() => {
        const getUsers = async() => {
            try {
                const res = await axios.get(`${proxy}/user/all-users`, {
                    headers: { Authorization: token }
                })

                setUsers(res.data.users)
                setLoadingPage(false)
                setLoadingUsers(false)
            } catch (err) {
                err.response.data.msg &&
                showMsgInSeconds(setMsg, msg, 'users', {err: err.response.data.msg, success: ''}, msgDuration)
                setLoadingPage(false)
                setLoadingUsers(false)
            }
        }
        if(token) getUsers()
    }, [token, cbUsers])

    useEffect(() => {
        setLoadingUser(true)
        const getUser = async(id) => {
            try {
                const res = await axios.get(`${proxy}/user/by-id/${id}`, {
                    headers: { Authorization: token }
                })
                setElement(res.data.user)
                setLoadingUser(false)
            } catch (err) {
                console.log(err)
                setLoadingUser(false)
            }
        }
        if(elementID) getUser(elementID)
    }, [elementID, cbUser])

    const handleUpdateField = async(e) => {
        e.preventDefault()
        const {id, value} = e.target
        const [input, prop] = id.split(' ')

        if(value === element[prop]) return
        if(!value) return document.getElementById(id).value = element[prop]
        const confirmContinue = window.confirm(`Update field with '${prop === 'role' ? role(Number(value)) : value}' ?`)
        if(!confirmContinue) return document.getElementById(id).value = element[prop]
        
        const loaderID = 'loader-'+String(e.target.parentElement.id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'

        try {
            const res = await axios.put(`${proxy}/user/update/by-id/${elementID}`, {prop, value},{
                headers: { Authorization: token }
            })
            setCbUsers(!cbUsers)
            setCbUser(!cbUser)
            document.getElementById(loaderID).style.display = 'none'
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const missingData = (section) => {
        if(!element) return
        switch (section) {
            case 'info':
                const {username, email} = element
                if(!username || !email) return true
                break;
            case 'avatar':
                break;
            case 'role':
                break;
            default:
                break;
        }
        return false
    }

    const handleUpdateImage = async(e) => {
        //e.preventDefault()
        const file = e.target.files[0]

        if(!file) return alert("No file selected")
        
        if(file.type !== 'image/jpeg' && file.type !== 'image/png')
            return alert("Incorrect format")

        if(file.size > 1024 * 1024)
            return alert("File is too large")

        const loaderID = 'loader-'+String(e.target.parentElement.id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'
        try {
            let formData = new FormData()
            formData.append('file', file)
            formData.append('prop', e.target.id.split('-')[1])
            formData.append('folder', 'users')
            const res = await axios.post(`${proxy}/file/upload-user-image/by_id/${elementID}`, formData, {
                headers: {
                    Authorization: token,
                    'content-type': 'multipart/form-data'
                }
            })
            setCbUsers(!cbUsers)
            setCbUser(!cbUser)
            document.getElementById(loaderID).style.display = 'none'
        } catch (err) {
            err.response.data.msg &&
            alert(err.response.data.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const handleDeleteImage = async(e) => {
        e.preventDefault()
        const [action, prop] = e.target.id.split(' ')
        const confirmContinue = window.confirm(`Remove this image?`)
        if(!confirmContinue) return
        
        const loaderID = 'loader-'+String(e.target.parentElement.children[2].id.split('-')[2])
        document.getElementById(loaderID).style.display = 'block'
        try {
            const res = await axios.delete(`${proxy}/user/delete-image/by-id/${elementID}?prop=${prop}&folder=users`,{
                headers: { Authorization: token }
            })
            document.getElementById(loaderID).style.display = 'none'
            setCbUser(!cbUser)
        } catch (err) {
            err.response?.data?.msg && alert(err.response?.data?.msg)
            document.getElementById(loaderID).style.display = 'none'
        }
    }

    const handleDelete = async(element) => {
        const confirmContinue = window.confirm(`Delete user ${element.username.toUpperCase()} ?`)
        if(!confirmContinue) return
        setLoadingPage(true)
        try {
            const res = await axios.delete(`${proxy}/user/delete/by-id/${element._id}`,{
                headers: { Authorization: token }
            })
            setElementID(null)
            setCbUsers(!cbUsers)
            setLoadingPage(false)
        } catch (err) {
            err.response.data.msg && alert(err.response.data.msg)
            setLoadingPage(false)
        }
    }
    
    return (
        <Profile>
            {loadingPage ? <div style={{fontSize: '100px'}}><Loader/></div>
            :
            <div id='users'  className='auth-wrap'>
                <section>
                    <div className='title-container'>
                        <div className='flexrow align-ce'>
                            <p className='title'>Users</p>        
                            <div className="msg">
                                {msg.users.err && errMsg(msg.users.err)}
                                {msg.users.success && successMsg(msg.users.success)}
                            </div>
                        </div>
                    </div>

                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Created</th>
                                    <th>Updated</th>
                                    <th><FontAwesomeIcon icon="fa-solid fa-trash" /></th>
                                </tr>
                            </thead>
                            {!loadingUsers &&
                            <tbody>
                                {users?.length === 0? 
                                <tr>
                                    <td colSpan={100}>
                                        <p>No data found</p>
                                    </td>
                                </tr>
                                :users?.map((el) => {
                                    return (
                                        <tr key={el._id} className='table-row-data pointer' onClick={()=> setElementID(el._id)}>
                                            <td>{el.username}</td>
                                            <td>{el.email}</td>
                                            <td>{new Date(el.createdAt).toLocaleDateString('en-US')}</td>
                                            <td>{new Date(el.updatedAt).toLocaleDateString('en-US')}</td>
                                            <th className='delete-button' onClick={()=>handleDelete(el)}><FontAwesomeIcon icon="fa-solid fa-xmark"/></th>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                        </table>
                        {loadingUsers && 
                        <div className='loader-users'>
                            <Loader />
                        </div>}
                    </div>
                </section>
                {elementID &&
                <section id='element' className='element-page'>
                {loadingUser ? <div style={{fontSize: '100px'}}><Loader/></div>
                :<>
                    <div className='icons-container'>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" title='Close' className='close-element-icon clickable-icon' onClick={()=>setElementID(null)}/>
                    </div>

                    <div className='subsection'>
                        <p className='subsection-title'>User Info {missingData('info') ? <span className='missing-data'>Missing data!</span> : ''}</p>
                        <p className='subsection-subtitle'>Every field can be updated, just type in a new value and confirm</p>
                        <div className='subsection-content margin-h-auto'>
                            <div className='content-card'>
                                <p className='content-card-label'>Username</p>
                                <div id='with-loader-00' className='update-field-container'>
                                    <input className='update-field' id='input username' defaultValue={element?.username} onBlur={handleUpdateField}/>
                                    <div id='loader-00' className='loader-container'><Loader /></div>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Email</p>
                                <div id='with-loader-03' className='update-field-container'>
                                    <input className='update-field' id='input email' defaultValue={element?.email} onBlur={handleUpdateField}/>
                                    <div id='loader-03' className='loader-container'><Loader /></div>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Name</p>
                                <div id='with-loader-01' className='update-field-container'>
                                    <input className='update-field' id='input name' defaultValue={element?.name} onBlur={handleUpdateField}/>
                                    <div id='loader-01' className='loader-container'><Loader /></div>
                                </div>
                            </div>
                            <div className='content-card'>
                                <p className='content-card-label'>Last Name</p>
                                <div id='with-loader-02' className='update-field-container'>
                                    <input className='update-field' id='input lastName' defaultValue={element?.lastName} onBlur={handleUpdateField}/>
                                    <div id='loader-02' className='loader-container'><Loader /></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='subsection'>
                        <p className='subsection-title'>Avatar</p>
                        <p className='subsection-subtitle'>Click on the image box and update the user's avatar</p>
                        <div className='subsection-content'>
                            <div className='content-card'>
                                <p className='content-card-label'>Avatar</p>
                                {element?.avatar && element.avatar !== defaultAvatar && <p className='remove' id='delete avatar' onClick={handleDeleteImage}>REMOVE</p>}
                                <div id='with-loader-10' className='update-field-container'>
                                    <input type='file' className='hidden-file-input' id='file-avatar' onChange={handleUpdateImage}/>
                                    {element?.avatar ?
                                    <img className='update-field image-field' src={element?.avatar ?? ''} alt='user avatar update field' onClick={()=>document.getElementById('file-avatar').click()}/>
                                    :
                                    <p className='update-field image-field' onClick={()=>document.getElementById('file-avatar').click()}>&#9656; Select new avatar</p>}
                                    <div id='loader-10' className='loader-container'><Loader /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='subsection'>
                        <p className='subsection-title'>Role</p>
                        <p className='subsection-subtitle'>Select the user's role</p>
                        <div className='subsection-content'>
                            <div className='content-card'>
                                <p className='content-card-label'>Roles</p>
                                <div id='with-loader-20' className='update-field-container'>
                                    <select className='update-field' id='text role' value={element?.role} onChange={handleUpdateField}>
                                        <option value={0}>Super Admin</option>
                                        <option value={1}>Admin</option>
                                    </select>
                                    <div id='loader-20' className='loader-container'><Loader /></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='button-container'>
                        <button className='delete-button' onClick={()=>handleDelete(element)}>Delete user</button>
                    </div>
                </>}
                </section>}
            </div>
            }
        </Profile>
    )
}
